import React from "react";
import pick from "lodash/pick";
import MuiTypography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import type { Theme, StyleRules } from "@material-ui/core/styles";
import type { Typography as TypographyClasses } from "@material-ui/core/styles/createTypography";
import type { TypographyTypeMap } from "@material-ui/core/Typography";
import type { OverridableComponent } from "@material-ui/core/OverridableComponent";

const useStyles = makeStyles<
	Theme,
	CustomTypographyTypeMap["props"],
	keyof TypographyClasses
>(
	(theme) =>
		pick(
			theme.typography,
			theme.typography.customClasses,
		) as unknown as StyleRules<keyof TypographyClasses>,
);

type CustomVariants = Exclude<
	| NonNullable<TypographyTypeMap["props"]>["variant"]
	| keyof TypographyClasses,
	undefined
>;

type TypographyTypeMapProps = Omit<
	TypographyTypeMap["props"],
	"variant" | "variantMapping"
>;
interface CustomTypographyTypeMap<
	P = Record<string, unknown>,
	D extends React.ElementType = "span",
> extends Omit<TypographyTypeMap<P, D>, "props"> {
	props: P &
		TypographyTypeMapProps & {
			style?: React.CSSProperties;
			className?: string;
			variant?: CustomVariants;
			variantMapping?: Partial<Record<CustomVariants, string>>;
		};
}

const MuiTypographyComponent =
	MuiTypography as OverridableComponent<CustomTypographyTypeMap>;

export const Typography: React.FC<CustomTypographyTypeMap["props"]> = ({
	variant,
	className,
	...props
}) => {
	const styles = useStyles(props);

	const customClassName =
		variant &&
		variant in styles &&
		styles[variant as keyof TypographyClasses];

	const typographyProps = {
		variant: !customClassName ? variant : undefined,
		...props,
	};

	return (
		<MuiTypographyComponent
			className={clsx(customClassName, className)}
			{...typographyProps}
		/>
	);
};
